import React from 'react'
import styled from 'styled-components'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'

import { News } from '../components/News'

const IndexPage = () => (
  <Layout>
    <SEO
      title='遠隔医療で健幸へ導くリモハブのニュース'
      description='代表谷口のセミナー登壇や学会発表、企業展示など、Remohab（リモハブ）のニュースリリースを掲載しています。新聞・雑誌その他、特集メディアの掲載情報もこちらからご覧いただけます。'
      keywords={['ニュース','研究発表','学会','メディア','プレス','リモハブ','remohab']}
    />
    <H1>ニュース　研究発表　学会　メディア　プレス | Remohab リモハブ</H1>
    <News />
  </Layout>
)

const H1 = styled.h1`
  font-size: 1.8rem;
`

export default IndexPage
