import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Reveal } from 'react-genie'
import { customMedia } from '../common/customMedia'
import { parse } from 'querystring'
import { News } from '../../models/news'
import { Image } from '../common/Image'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation, Trans } from 'react-i18next'

export interface Props {
  list: News[]
  isLoading: boolean
}

export interface Handlers {
  fetchList: (params?: { [key: string]: string | number }) => void
}


export const NewsList = (props: Props & Handlers) => {
  const { list, isLoading } = props
  const { fetchList } = props

  const [t, i18n] = useTranslation()

  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const query = parse(window.location.search.slice(1))
    fetchList({ draftKey: query.draftKey as string })

    setTimeout(() => {
      const hash = window.location.hash.slice(1)
      const element = document.getElementById(hash)

      window.scrollTo({
        behavior: element ? 'smooth' : 'auto',
        top: element ? element.offsetTop - 80 : 0
      })
    }, 100)
  }, [])

  useEffect(() => {
    setOffset(list.length)
  }, [list])

  const fetchMore = () => {
    fetchList({ offset })
  }

  return (
    <>
      <Container>
        <Wrapper>
          <Reveal delay={500}>
            <NewsIconWrapper>
              <Image path="news@2x.png" />
            </NewsIconWrapper>
          </Reveal>
            <InfiniteScroll
              dataLength={list.length}
              next={fetchMore}
              hasMore={true}
              loader=""
            >
              <NewsListWrapper>
                {list.map((n, i) => (
                  <NewsCardWrapper key={i} id={n.id} hasLink={n.url}>
                    <a href={n.url} target="_blank">
                      <Reveal delay={500}>
                        <NewsCard>
                          <ImgWrapper>
                            <Img src={n.image.url} alt={n.content} />
                            <Mask />
                          </ImgWrapper>
                          <ContentWrapper>
                            <Metadata>
                              <Category>
                                {n.category.title}
                              </Category>
                              <DateText>
                                {n.date}
                              </DateText>
                            </Metadata>
                            <Content>
                              <Sp>
                                {i18n.language === 'ja' ? n.content.length <= 50 ? n.content : n.content.substr(0, 50) + '..' 
                                  : n.enContent.length <= 80 ? n.enContent : n.enContent.substr(0, 80) + '..'}
                              </Sp>
                              <Pc>
                                {i18n.language === 'ja' ? n.content : n.enContent}
                              </Pc>
                            </Content>
                            {n.url && <TextLink>
                              {t('readMore')} ＞
                            </TextLink>}
                          </ContentWrapper>
                        </NewsCard>
                      </Reveal>
                    </a>
                  </NewsCardWrapper>
                ))}
              </NewsListWrapper>
            </InfiniteScroll>
        </Wrapper>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 0 20px;
  `}

  background-color: #efefef;
`

const Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 80px 0;
  `}
  ${customMedia.between("medium", "large")`
    margin: 0 auto;
    padding: 120px 0;
    width: 735px;
  `}
  ${customMedia.greaterThan("large")`
    margin: 0 auto;
    padding: 160px 0;
    width: 1000px;
  `}
`

const NewsIconWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto 35px;
    width: 50px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto 60px;
    width: 100px;
  `}

`

const NewsListWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  `}
`

const NewsCardWrapper = styled.div<{hasLink: boolean | string}>`
  ${customMedia.lessThan("medium")`
    margin-bottom: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-bottom: 40px;
  `}

  ${props => props.hasLink && 'cursor: pointer;'}
`

const NewsCard = styled.div`
  display: -webkit-flex;
  display: flex;
`

const ImgWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    width: 100px;
  `}
  ${customMedia.greaterThan("medium")`
    width: 200px;
  `}

  flex: none;
  position: relative;

  :hover {
    div {
      display: block;
    }
  }
`

const Img = styled.img`
`

const Mask = styled.div`
  ${customMedia.lessThan("medium")`
    height: 100px;
    width: 100px;
  `}
  ${customMedia.greaterThan("medium")`
    height: 200px;
    width: 200px;
  `}

  background-color: rgba(38, 210, 173, .5);
  display: none;
  position: absolute;
  top: 0;
  left: 0;
`

const ContentWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-left: 13px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-left: 25px;
  `}

  -webkit-flex-grow: 1;
  flex-grow: 1;
  position: relative;
`

const Metadata = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
`

const Category = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 0.7rem;
    margin-right: 9px;
    padding: 0 5px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.4rem;
    margin-right: 18px;
    padding: 0 10px;
  `}

  color: #fff;
  font-weight: bold;
  background-color: #26d2ad;
`

const DateText = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.2rem;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2rem;
    line-height: 30px;
  `}

  color: #26d2ad;
  font-weight: bold;
`

const Content = styled.h1`
  ${customMedia.lessThan("medium")`
    font-size: 1.1rem;
    line-height: 14px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.8rem;
    line-height: 30px;
  `}

  color: #60656b;
  margin-top: 8px; 
`
const TextLink = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.2rem;
    position: absolute;
    bottom: 0;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2rem;
    margin-bottom: 20px;
  `}

  color: #26d2ad;
  cursor: pointer;
  font-weight: bold;
`

const Loading = styled.img`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 80px
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto;
    width: 100px
  `}
`

const Sp = styled.div`
  ${customMedia.lessThan("medium")`
    display: block;
  `}
  ${customMedia.greaterThan("medium")`
    display: none;
  `}
`
const Pc = styled.div`
  ${customMedia.lessThan("medium")`
    display: none;
  `}
  ${customMedia.greaterThan("medium")`
    display: block;
  `}
`