import { connect } from 'react-redux'
import { NewsList as Base, Props, Handlers } from './News'
import { RootState } from '../../state/types'
import {
  getList,
  getIsLoading
} from '../../state/news/selectors'
import { fetchList } from '../../state/news/operations'

const mapStateToProps = (state: RootState): Props => ({
  list: getList(state),
  isLoading: getIsLoading(state)
})

const mapDispatchToProps: Handlers = {
  fetchList
}

export const News = connect(mapStateToProps, mapDispatchToProps)(Base)
